/*
 * Servizio che si occupa della visibilità di un oggetto. Permette dunque di cercare una persona nel tenant e di suggerirgli un learning plan
*/
module VisibilityService {
    let app = angular.module("app");

    app.factory("VisibilityService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Ricerca gli utenti nel proprio tenant
            getPersonsInTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-persons-in-tenant/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            
            // Recupera il numero degli utenti nel proprio tenant
            countPersonsInTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-persons-in-tenant', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            
            // Aggiunta una visibilità
            addVisibility: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/add-visibility', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            
            // Aggiunta la visibilità di tipo 'suggestion' (quindi suggerisce un oggetto a un utente)
            addSuggestion: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/add-suggestion', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        };
        return serv;
    }]);
}